// extracted by mini-css-extract-plugin
export var backgroundScene = "index-page-module--background-scene--3sK3b";
export var contactContainer = "index-page-module--contact-container--Ae0hR grid--row--E93eT";
export var contactWrapper = "index-page-module--contact-wrapper--E9A3l grid--col-sm-6--7dy9X";
export var deskSequence = "index-page-module--desk-sequence--TfD76";
export var hero = "index-page-module--hero--w6gDj grid--row--E93eT";
export var heroHeader = "index-page-module--hero-header--+ZSil type--font-museo--Rt53G";
export var heroRightWrapper = "index-page-module--hero-right-wrapper--7EcyM grid--col-md-6--uS2Fp grid--push-md-1--IjwEr";
export var heroSubHeader = "index-page-module--hero-sub-header--xJfA7 type--font-futura--HOUH4 type--heading-s--JfTSy";
export var heroWrapper = "index-page-module--hero-wrapper--OsVYf grid--col-sm-7--mpuXB grid--col-md-4--DgprU grid--push-md-1--IjwEr";
export var line = "index-page-module--line---SKnf elements--divider--9oGzu";
export var scrollHeader = "index-page-module--scroll-header--qr16s type--font-essonnes--k0IEZ type--heading-xxl--rf7Fn";
export var scrollHint = "index-page-module--scroll-hint--gZnVo type--font-futura--HOUH4 type--text-s--KsBVV";
export var scrollScene = "index-page-module--scroll-scene--aYOp4";
export var scrollText = "index-page-module--scroll-text--XEL+I type--text-xl----Jd6";
export var scrollTextLeft = "index-page-module--scroll-text-left--ucqla grid--row--E93eT";
export var scrollTextRight = "index-page-module--scroll-text-right--B296p grid--row--E93eT";
export var scrollTextWrapper = "index-page-module--scroll-text-wrapper--oVsoS grid--col-sm-10--Pm6tZ grid--col-md-5--LXKU7 grid--col-lg-4--RuHdH";